<template>
    <v-app>
      <v-system-bar class="d-print-none pt-3" height="30" app lights-out>
        <v-toolbar-items class="text-right">
          <v-btn
            large
            rounded
            dark
            href="javascript:window.print()"
            class="title mr-2 rounded"
            color="info"
          >
            <v-icon class="mr-1" color="white" large>mdi-printer</v-icon>พิมพ์
          </v-btn>
        </v-toolbar-items>
      </v-system-bar>
  
      <div id="exportContent">
        <div class="page">
          <div align="center">
            <div class="head">
              ใบสมัครเข้ารับการคัดเลือกเพื่อบรรจุและแต่งตั้งให้ดำรงตำแหน่งผู้อำนวยการสถานศึกษา
            </div>
            <div class="head">
              สังกัดสำนักงานคณะกรรมการการอาชีวศึกษา
              <u v-if="sedirector_apps.sedirector_app_type === 'inside'"
                >ยกเว้นเขต</u
              ><u v-else-if="sedirector_apps.sedirector_app_type === 'outside'"
                >ในเขต</u
              >
              พัฒนาพิเศษเฉพาะกิจจังหวัดชายแดนภาคใต้
            </div>
            <div class="head">
              เฉพาะจังหวัดยะลา นราธิวาส ปัตตานี และ 4 อำเภอ ในจังหวัดสงขลา
            </div>
            <div class="head">
              (อำเภอจะนะ อำเภอสะบ้าย้อย อำเภอเทพา และอำเภอนาทวี)
            </div>
          </div>
          <table width="100%">
            <tr>
              <td></td>
              <td class="td text-center text_j_1" width="72px" height="90px">
                <v-img
                  v-if="sedirector_apps.sedirector_app_image"
                  contain
                  height="90px"
                  width="72px"
                  :src="'https://hrvec.ovec.go.th/HrvecRegisDir/' + sedirector_apps.sedirector_app_image"
                ></v-img>
                <img
                  v-else
                  height="85px"
                  width="72px"
                  src="https://hrvec.ovec.go.th/HRvecfiles/blank-human-image.png"
                />
              </td>
            </tr>
          </table>
  
          <table border="0" width="100%">
            <tr>
              <td width="10%">1. ชื่อ</td>
              <td width="40%" class="td_line">
                <div class="regular16 text-center font-weight-bold">
                  {{ sedirector_apps.title_s }}{{ sedirector_apps.frist_name }}
                </div>
              </td>
              <td width="10%" class="text-center">สกุล</td>
              <td width="40%" class="td_line">
                <div class="regular16 text-center font-weight-bold">
                  {{ sedirector_apps.last_name }}
                </div>
              </td>
            </tr>
          </table>
  
          <table border="0" class="text_j mt-5" width="100%">
            <tr>
              <td width="100%">2. คุณวุฒิทางการศึกษา</td>
            </tr>
          </table>
  
          <table border="0" class="text_j" width="100%">
            <tr v-for="item in personnel_educations" :key="item.id">
              <td width="10%"></td>
              <td width="10%">{{ item.education_level }}</td>
              <td width="20%" class="td_line">
                <div class="regular16 text-center font-weight-bold">
                  {{ item.faculty_name }}
                </div>
              </td>
              <td width="10%" class="text-center">สาขา</td>
              <td width="40%" class="td_line">
                <div class="regular16 text-center font-weight-bold">
                  {{ item.branch_name }}
                </div>
              </td>
            </tr>
          </table>
  
          <table border="0" class="text_j mt-5" width="100%">
            <tr>
              <td width="35%">3. วันบรรจุเข้ารับราชการ เมื่อวันที่</td>
              <td class="td_line">
                <div class="regular16 text-center font-weight-bold">
                  {{
                    date_app_frist
                      | moment("add", "543 years")
                      | moment("D MMMM YYYY")
                  }}
                </div>
              </td>
            </tr>
          </table>
  
          <table border="0" class="text_j" width="100%">
            <tr>
              <td width="2%"></td>
              <td width="10%">ในตำแหน่ง</td>
              <td width="33%" class="td_line">
                <div class="regular16 text-center font-weight-bold">
                  ครูผู้ช่วย/ครู
                </div>
              </td>
              <td width="20%" class="text-center">มีอายุราชการ รวม</td>
              <td width="25%" class="td_line">
                <div class="regular16 text-center font-weight-bold">
                  {{ sedirector_apps.sedirector_app_agework }}
                </div>
              </td>
            </tr>
          </table>
  
          <table border="0" class="text_j" width="100%">
            <tr>
              <td width="2%"></td>
              <td width="98%">(นับถึงวันสุดท้ายของการรับสมัคร)</td>
            </tr>
          </table>
  
          <table border="0" class="text_j mt-5" width="100%">
            <tr>
              <td width="25%">4. ปัจจุบันดำรงตำแหน่ง</td>
              <td width="15%" class="td_line">
                <div class="regular16 text-center font-weight-bold">
                  {{ sedirector_apps.position_name }}
                </div>
              </td>
              <td width="15%" class="text-center">อันดับ/ระดับ</td>
              <td width="5%" class="td_line">
                <div class="regular16 text-center font-weight-bold">
                  {{ sedirector_apps.rang_level }}
                </div>
              </td>
              <td width="10%" class="text-center">วิทยฐานะ</td>
              <td width="20%" class="td_line">
                <div class="regular16 text-center font-weight-bold">
                  {{ sedirector_apps.rang_name }}
                </div>
              </td>
            </tr>
          </table>
  
          <table border="0" class="text_j" width="100%">
            <tr>
              <td width="2%"></td>
              <td width="10%">เงินเดือน</td>
              <td width="20%" class="td_line">
                <div class="regular16 text-center font-weight-bold">
                  {{ sedirector_apps.salary_s }}
                </div>
              </td>
              <td width="20%" class="text-center">บาท สถานศึกษา</td>
              <td class="td_line">
                <div class="regular16 text-center font-weight-bold">
                  {{ sedirector_apps.user_firstname }}
                </div>
              </td>
            </tr>
          </table>
  
          <table border="0" class="text_j" width="100%">
            <tr>
              <td width="2%"></td>
              <td width="10%">อำเภอ</td>
              <td width="20%" class="td_line">
                <div class="regular16 text-center font-weight-bold">
                  {{ sedirector_apps.prefecture_name }}
                </div>
              </td>
              <td width="10%" class="text-center">จังหวัด</td>
              <td width="20%" class="td_line">
                <div class="regular16 text-center font-weight-bold">
                  {{ sedirector_apps.province_name }}
                </div>
              </td>
              <td width="10%" class="text-center">โทรศัพท์</td>
              <td class="td_line">
                <div class="regular16 text-center font-weight-bold">
                  {{ sedirector_apps.tel_p || "-" }}
                </div>
              </td>
            </tr>
          </table>
  
          <table border="0" class="text_j" width="100%">
            <tr>
              <td width="2%"></td>
              <td width="30%">ดำรงตำแหน่งปัจจุบันเมื่อวันที่</td>
              <td class="td_line">
                <div
                  class="regular16 text-center font-weight-bold"
                  v-if="String(sedirector_apps.date_app_positonnow).length > 5"
                >
                  {{
                    sedirector_apps.date_app_positonnow
                      | moment("add", "543 year")
                      | moment("D MMMM YYYY")
                  }}
                </div>
                <div v-else>
                  <v-icon color="warning">mdi-alert</v-icon>
                  กรุณาแจ้งงานบุคลากรปรับปรุงข้อมูล
                </div>
              </td>
            </tr>
          </table>
  
          <table border="0" class="text_j" width="100%">
            <tr>
              <td width="2%"></td>
              <td width="35%">รวมระยะเวลาที่ดำรงตำแหน่งปัจจุบัน</td>
              <td class="td_line">
                <div class="regular16 text-center font-weight-bold">
                  {{ sedirector_apps.sedirector_age_position || "-" }}
                </div>
              </td>
            </tr>
          </table>
  
          <table border="0" class="text_j mt-5" width="100%">
            <tr>
              <td>
                5. ดำรงตำแหน่ง หรือเคยดำรงตำแหน่ง ดังนี้ (การนับระยะเวลา ให้นับถึงวันสุดท้ายของการรับสมัคร)
              </td>
            </tr>         
          </table>
  
          <table border="0" class="text_j" width="100%">
            <tr>
              <td width="2%"></td>
              <td>
                <v-icon v-if="sedirector_apps.sedirector_app_position === 'teach'"
                  >mdi-checkbox-marked-circle-outline</v-icon
                >
                <v-icon v-else>mdi-checkbox-blank-circle-outline</v-icon>
                5.1 ตำแหน่งรองผู้อำนวยการสถานศึกษา
              </td>
            </tr>
          </table>
  
          <table border="0" class="text_j" width="100%">
            <tr>
              <td width="5%"></td>
              <td width="15%">ตั้งแต่วันที่</td>
              <td width="30%" class="td_line">
                <div
                  v-if="sedirector_apps.sedirector_app_position === 'teach'"
                  class="regular16 text-center font-weight-bold"
                >
                  {{
                    sedirector_apps.sedirector_app_start
                      | moment("add", "543 years")
                      | moment("D MMMM YYYY")
                  }}
                </div>
              </td>
              <td width="15%" class="text-center">ถึงวันที่</td>
              <td width="30% " class="td_line">
                <div
                  v-if="sedirector_apps.sedirector_app_position === 'teach'"
                  class="regular16 text-center font-weight-bold"
                >
                  {{
                    sedirector_apps.sedirector_app_end
                      | moment("add", "543 years")
                      | moment("D MMMM YYYY")
                  }}
                </div>
              </td>
            </tr>
          </table>
  
          <table border="0" class="text_j" width="100%">
            <tr>
              <td width="5%"></td>
              <td width="15%">รวมระยะเวลา</td>
              <td class="td_line">
                <div
                  v-if="sedirector_apps.sedirector_app_position === 'teach'"
                  class="regular16 text-center font-weight-bold"
                >
                  {{ sedirector_apps.sedirector_age_position_work5 }}
                </div>
              </td>
            </tr>
          </table>
  
          <table border="0" class="text_j" width="100%">
            <tr>
              <td width="2%"></td>
              <td>
                <v-icon v-if="sedirector_apps.sedirector_app_position === 'head'"
                  >mdi-checkbox-marked-circle-outline</v-icon
                >
                <v-icon v-else>mdi-checkbox-blank-circle-outline</v-icon> 5.2
                ตำแหน่งครู ที่มีวิทยฐานะไม่ต่ำกว่าครูชำนาญการพิเศษ และมีประสบการณ์การบริหาร ไม่ต่ำกว่า หัวหน้ากลุ่ม
              </td>
            </tr>
          </table>
  
          <table border="0" class="text_j" width="100%">
            <tr>
              <td width="5%"></td>
              <td width="15%">ตั้งแต่วันที่</td>
              <td width="30%" class="td_line">
                <div
                  v-if="sedirector_apps.sedirector_app_position === 'head'"
                  class="regular16 text-center font-weight-bold"
                >
                  {{
                    sedirector_apps.sedirector_app_start
                      | moment("add", "543 years")
                      | moment("D MMMM YYYY")
                  }}
                </div>
              </td>
              <td width="15%" class="text-center">ถึงวันที่</td>
              <td width="30% " class="td_line">
                <div
                  v-if="sedirector_apps.sedirector_app_position === 'head'"
                  class="regular16 text-center font-weight-bold"
                >
                  {{
                    sedirector_apps.sedirector_app_end
                      | moment("add", "543 years")
                      | moment("D MMMM YYYY")
                  }}
                </div>
              </td>
            </tr>
          </table>
  
          <table border="0" class="text_j" width="100%">
            <tr>
              <td width="5%"></td>
              <td width="15%">รวมระยะเวลา</td>
              <td class="td_line">
                <div
                  v-if="sedirector_apps.sedirector_app_position === 'head'"
                  class="regular16 text-center font-weight-bold"
                >
                  {{ sedirector_apps.sedirector_age_position_work5 }}
                </div>
              </td>
            </tr>
          </table>
  
          <table border="0" class="text_j" width="100%">
            <tr>
              <td width="2%"></td>
              <td>
                <v-icon
                  v-if="sedirector_apps.sedirector_app_position === 'director'"
                  >mdi-checkbox-marked-circle-outline</v-icon
                >
                <v-icon v-else>mdi-checkbox-blank-circle-outline</v-icon> 5.3 ตำแหน่งศึกษานิเทศก์ ที่มีวิทยฐานะไม่ต่ำกว่าศึกษานิเทศก์ชำนาญการพิเศษ และมีประสบการณ์การบริหาร ไม่ต่ำกว่า หัวหน้ากลุ่ม หัวหน้าหน่วย หรือผู้อำนวยการการกลุ่ม
              </td>
            </tr>
          </table>
 

          <table border="0" class="text_j" width="100%">
            <tr>
              <td width="5%"></td>
              <td width="15%">ตั้งแต่วันที่</td>
              <td width="30%" class="td_line">
                <div
                  v-if="sedirector_apps.sedirector_app_position === 'director'"
                  class="regular16 text-center font-weight-bold"
                >
                  {{
                    sedirector_apps.sedirector_app_start
                      | moment("add", "543 years")
                      | moment("D MMMM YYYY")
                  }}
                </div>
              </td>
              <td width="15%" class="text-center">ถึงวันที่</td>
              <td width="30% " class="td_line">
                <div
                  v-if="sedirector_apps.sedirector_app_position === 'director'"
                  class="regular16 text-center font-weight-bold"
                >
                  {{
                    sedirector_apps.sedirector_app_end
                      | moment("add", "543 years")
                      | moment("D MMMM YYYY")
                  }}
                </div>
              </td>
            </tr>
          </table>
  
          <table border="0" class="text_j" width="100%">
            <tr>
              <td width="5%"></td>
              <td width="15%">รวมระยะเวลา</td>
              <td class="td_line">
                <div
                  v-if="sedirector_apps.sedirector_app_position === 'director'"
                  class="regular16 text-center font-weight-bold"
                >
                  {{ sedirector_apps.sedirector_age_position_work5 }}
                </div>
              </td>
            </tr>
          </table>
  
          
       

          <p class="indent text_j_1 text_al">
            ทั้งนี้ กรณีผู้สมัคร ดำรงตำแหน่ง หรือเคยดำรงตำแหน่ง ตามข้อ 5.2 และ ข้อ 5.3 ให้จัดส่งเอกสารหลักฐานที่แสดงว่าเป็นผู้มีประสบการณ์การบริหาร ตามที่ระบุข้างต้น
          </p>


          <!-- New Page -->
          <p style="page-break-before: always;">&nbsp;</p>
          <!-- 2 -->
  
          <br />
          <br />
  
          <table border="0" class="text_j" width="100%">
            <tr>
              <td>6. การรักษาวินัย ทั้งนี้ ผู้ใดได้รับการล้างมลทิน ตาม พ.ร.บ. ล้างมลทินให้ถือว่าเป็นผู้เคยถูกลงโทษทางวินัย</td>
            </tr>
          </table>
  
          <table border="0" class="text_j" width="100%">
            <tr>
              <td width="5%"></td>
              <td>
                <v-icon
                  v-if="sedirector_apps.sedirector_app_discipline === 'ever'"
                  >mdi-checkbox-marked-circle-outline</v-icon
                >
                <v-icon v-else>mdi-checkbox-blank-circle-outline</v-icon>
                เคยถูกลงโทษทางวินัย
              </td>
            </tr>
            <tr>
              <td width="5%"></td>
              <td>
                <v-icon
                  v-if="sedirector_apps.sedirector_app_discipline === 'never'"
                  >mdi-checkbox-marked-circle-outline</v-icon
                >
                <v-icon v-else>mdi-checkbox-blank-circle-outline</v-icon>
                ไม่เคยถูกลงโทษทางวินัย
              </td>
            </tr>
          </table>
  
          <div v-if="sedirector_apps.sedirector_app_type != 'inside'">
            <table border="0" class="text_j mt-5" width="100%">
              <tr>
                <td colspan="2">
                  7.
                  การปฏิบัติงานในเขตพัฒนาพิเศษเฉพาะกิจจังหวัดชายแดนภาคใต้เฉพาะจังหวัดยะลา
                  นราธิวาส ปัตตานี
                </td>
              </tr>
              <tr>
                <td width="2%"></td>
                <td>
                  และ 4 อำเภอ ในจังหวัดสงขลา (อำเภอจะนะ อำเภอสะบ้าย้อย อำเภอเทพา
                  และอำเภอนาทวี)
                </td>
              </tr>
            </table>
  
            <table border="0" class="text_j" width="100%">
              <tr>
                <td width="5%"></td>
                <td>
                  <v-icon v-if="sedirector_apps.sedirector_app_south === 'ever'"
                    >mdi-checkbox-marked-circle-outline</v-icon
                  >
                  <v-icon v-else>mdi-checkbox-blank-circle-outline</v-icon>
                  ไม่เคยปฏิบัติงานในพื้นที่
                </td>
              </tr>
              <tr>
                <td width="5%"></td>
                <td>
                  <v-icon v-if="sedirector_apps.sedirector_app_south === 'never'"
                    >mdi-checkbox-marked-circle-outline</v-icon
                  >
                  <v-icon v-else>mdi-checkbox-blank-circle-outline</v-icon>
                  ปฏิบัติ หรือ เคยปฏิบัติงานในพื้นที่
                </td>
              </tr>
            </table>
  
            <table border="0" class="text_j" width="100%">
              <tr>
                <td width="5%"></td>
                <td width="15%">ตั้งแต่วันที่</td>
                <td width="30%" class="td_line">
                  <div class="regular16 text-center font-weight-bold">
                    {{
                      sedirector_apps.sedirector_app_southdatebegin
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                  </div>
                </td>
                <td width="15%" class="text-center">ถึงวันที่</td>
                <td width="30% " class="td_line">
                  <div class="regular16 text-center font-weight-bold">
                    {{
                      sedirector_apps.sedirector_app_southend
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                  </div>
                </td>
              </tr>
            </table>
  
            <table border="0" class="text_j" width="100%">
              <tr>
                <td width="5%"></td>
                <td width="15%">ในตำแหน่ง</td>
                <td width="30%" class="td_line">
                  <div class="regular16 text-center font-weight-bold">
                    {{ sedirector_apps.sedirector_app_southposition }}
                  </div>
                </td>
                <td width="15%" class="text-center">สังกัด</td>
                <td width="30% " class="td_line">
                  <div class="regular16 text-center font-weight-bold">
                    {{ sedirector_apps.college_namesouth }}
                  </div>
                </td>
              </tr>
            </table>
  
            <table border="0" class="text_j" width="100%">
              <tr>
                <td width="5%"></td>
                <td width="15%">รวมระยะเวลา</td>
                <td class="td_line">
                  <div class="regular16 text-center font-weight-bold">
                    {{ get_age_south }}
                  </div>
                </td>
              </tr>
            </table>
          </div>
  
          <br />
  
          <p class="text_j_1 text_al">
            ข้าพเจ้าขอรับรองว่าข้อความที่บันทึกในใบสมัคร เอกสารประกอบการสมัคร
            และเอกสารสำหรับ ประเมินตามตัวชี้วัด
            และองค์ประกอบการประเมินที่แนบมาพร้อมนี้ เป็นความจริงทุกประการ
          </p>
  
          <br />
          <table class="text_j" border="0" width="100%" align="center">
            <tr>
              <td width="60%"></td>
              <td width="40%">
                <div class="regular16">
                  <span class="regular16"
                    >ลงชื่อ................................................ผู้สมัคร</span
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td width="60%"></td>
              <td width="40%" align="center">
                <div class="regular16">
                  <span class="regular16"
                    >( {{ sedirector_apps.title_s
                    }}{{
                      sedirector_apps.frist_name + " " + sedirector_apps.last_name
                    }}
                    )</span
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td width="60%"></td>
              <td width="40%">
                <div class="regular16 text-center">
                  {{ sedirector_apps.position_name + sedirector_apps.rang_name }}
                </div>
              </td>
            </tr>
            <tr>
              <td width="60%"></td>
              <td width="40%">
                <div class="regular16 text-center">
                  วันที่
                  {{
                    date_today_cal
                      | moment("add", "543 years")
                      | moment("D MMMM YYYY")
                  }}
                </div>
              </td>
            </tr>
          </table>
  
          <br />
          <table class="text_j" border="0" width="100%">
            <tr>
              <td class="text-right">
                ได้ตรวจสอบข้อมูลของผู้สมัครแล้ว ขอรับรองว่าเป็นความจริงทุกประการ
              </td>
            </tr>
          </table>
  
          <br />
  
          <table class="text_j" border="0" width="100%" align="center">
            <tr>
              <td width="60%"></td>
              <td width="40%">
                <div class="regular16">
                  <span class="regular16"
                    >ลงชื่อ............................................ผู้รับรอง</span
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td width="60%"></td>
              <td width="40%" align="center">
                <div class="regular16">
                  <span class="regular16"
                    >(..........................................................)</span
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td width="60%"></td>
              <td width="40%">
                <div class="regular16 text-center">
                  .................................................
                </div>
              </td>
            </tr>
            <tr>
              <td width="60%"></td>
              <td width="40%">
                <div class="regular16">
                  <span class="regular16"
                    >วันที่.............../...................../...................</span
                  >
                </div>
              </td>
            </tr>
          </table>
  
          <br />
          <br />
          <br />
  
          <div class="text_j_1">
            <span class="font-weight-bold"> <u>หมายเหตุ</u> </span>
            เอกสารประกอบการสมัคร และเอกสารสำหรับประเมินตามตัวชี้วัด
            และองค์ประกอบการประเมิน ทุกหน้า
            ผู้สมัครจะต้องลงนามรับรองความถูกต้องด้วยตนเอง
            และได้รับการลงนามรับรองความถูกต้อง โดยผู้อำนวยการสถานศึกษา
            หรือผู้รักษาการในตำแหน่งผู้อำนวยการสถานศึกษาต้นสังกัด 
          </div>
        </div>
      </div>
    </v-app>
  </template>
  
  <script>
  import moment from "moment-timezone";
  
  export default {
    name: "App",
    data: () => ({
      ApiKey: "HRvec2021",
      users: [],
      totalVuePackages: [],
      period_sedirectors: [],
      personnel_educations: [],
      rows: [],
      sedirector_apps: [],
      momentdate_app_pos: [],
      sedirector_app_positions1: [],
      sedirector_app_start1: [],
      sedirector_app_end1: [],
      sedirector_age_position_work51: [],
      sedirector_app_positions2: [],
      sedirector_app_start2: [],
      sedirector_app_end2: [],
      sedirector_age_position_work52: [],
      sedirector_app_positions3: [],
      sedirector_app_start3: [],
      sedirector_app_end3: [],
      sedirector_age_position_work53: [],
      sedirector_app_starts: [],
      sedirector_app_ends: [],
      sedirector_app_disciplines1: [],
      sedirector_app_disciplines2: [],
      momentdate_app_date2: []
    }),
  
    async mounted() {
      await this.sedirector_appQuery();
      await this.usersQuery();
  
      await this.personnel_educationsQueryAll();
  
      await this.period_sedirectorQuery();
  
      await this.fecthrows();
  
      await this.momentdate();
      await this.momentdateApp();
    },
  
    methods: {
      async usersQuery() {
        let result;
        result = await this.$http.post("personnel_temporary.php", {
          ApiKey: this.ApiKey,
          id_card: this.sedirector_apps.id_card
        });
        this.users = result.data;
      },
      async momentdateApp() {
        let date = new Date(this.users.date_app2);
        let result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "long",
          day: "numeric",
          weekday: "long"
        });
        this.momentdate_app_date2 = result;
      },
  
      async momentdate() {
        let date = new Date(this.users.date_app_positonnow);
        let result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "long",
          day: "numeric",
          weekday: "long"
        });
        this.momentdate_app_pos = result;
      },
  
      async sedirector_appQuery() {
        let result = await this.$http.post("sedirector_app.php", {
          ApiKey: this.ApiKey,
          sedirector_app_idref: this.sedirector_app_idref
        });
        this.sedirector_apps = result.data;
        ;
        let datestart = new Date(this.sedirector_apps.sedirector_app_start);
        let resultstart = datestart.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "long",
          day: "numeric",
          weekday: "long"
        });
        this.sedirector_app_starts = resultstart;
        let dateend = new Date(this.sedirector_apps.sedirector_app_end);
        let resultend = dateend.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "long",
          day: "numeric",
          weekday: "long"
        });
        this.sedirector_app_ends = resultend;
  
        if (this.sedirector_apps.sedirector_app_position == "teach") {
          this.sedirector_app_positions1 = "/";
          this.sedirector_app_start1 = this.sedirector_app_starts;
          this.sedirector_app_end1 = this.sedirector_app_ends;
          this.sedirector_age_position_work51 = this.sedirector_apps.sedirector_age_position_work5;
        } else if (this.sedirector_apps.sedirector_app_position == "head") {
          this.sedirector_app_positions2 = "/";
          this.sedirector_app_start2 = this.sedirector_app_starts;
          this.sedirector_app_end2 = this.sedirector_app_ends;
          this.sedirector_age_position_work52 = this.sedirector_apps.sedirector_age_position_work5;
        } else if (this.sedirector_apps.sedirector_app_position == "director") {
          this.sedirector_app_positions3 = "/";
          this.sedirector_app_start3 = this.sedirector_app_starts;
          this.sedirector_app_end3 = this.sedirector_app_ends;
          this.sedirector_age_position_work53 = this.sedirector_apps.sedirector_age_position_work5;
        }
  
        if (this.sedirector_apps.sedirector_app_discipline == "ever") {
          this.sedirector_app_disciplines1 = "/";
        } else {
          this.sedirector_app_disciplines2 = "/";
        }
      },
  
      async fecthrows() {
        let data = this.personnel_educations;
  
        data.forEach(value => {
          this.rows.push([
            " ",
            value.education_level,
            value.faculty_name,
            "สาขา",
            value.branch_name
          ]);
        });
      },
      async personnel_educationsQueryAll() {
        let result = await this.$http.post("personnel_education.php", {
          ApiKey: this.ApiKey,
          id_card: this.users.id_card
        });
        this.personnel_educations = result.data;
      },
  
      async period_sedirectorQuery() {
        let result_period;
        result_period = await this.$http.post("period_sedirector.php", {
          ApiKey: this.ApiKey,
          period_sedirector_enable: "1"
        });
        this.period_sedirectors = result_period.data;
      },
  
      async sweetAlertLoading() {
        Swal.fire({
          title: "Please Wait !",
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
          },
          onBeforeOpen: () => {
            Swal.showLoading();
          },
          onAfterClose: () => {
            Swal.hideLoading();
          },
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false
        });
      },
  
      Export2Doc(element, filename) {
        var preHtml =
          "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
        var postHtml = "</body></html>";
        var html =
          preHtml + document.getElementById(element).innerHTML + postHtml;
  
        var blob = new Blob(["\ufeff", html], {
          type: "application/msword"
        });
  
        // Specify link url
        var url =
          "data:application/vnd.ms-word;charset=utf-8," +
          encodeURIComponent(html);
  
        // Specify file name
        filename = filename ? filename + ".doc" : "document.doc";
  
        // Create download link element
        var downloadLink = document.createElement("a");
  
        document.body.appendChild(downloadLink);
  
        if (navigator.msSaveOrOpenBlob) {
          navigator.msSaveOrOpenBlob(blob, filename);
        } else {
          // Create a link to the file
          downloadLink.href = url;
  
          // Setting the file name
          downloadLink.download = filename;
  
          //triggering the function
          downloadLink.click();
        }
  
        document.body.removeChild(downloadLink);
      }
    },
    computed: {
      sedirector_app_idref() {
        let result = this.$route.query.id;
        return result;
      },
  
      date_app_frist() {
        let appoin_day = this.sedirector_apps.appoin_day;
        let appoin_month = this.sedirector_apps.appoin_month;
        let appoin_year = this.sedirector_apps.appoin_year;
        let years = parseInt(appoin_year - 543);
        let result = years + "-" + appoin_month + "-" + appoin_day;
        return result;
      },
      date_today_cal() {
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, "0");
        let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = today.getFullYear();
  
        today = yyyy + "-" + mm + "-" + dd;
        return today;
      },
  
      get_age_south() {
        let today = new Date(this.date_today_cal);
        let DOB = new Date(this.sedirector_apps.sedirector_app_southdatebegin);
        let totalMonths =
          (today.getFullYear() - DOB.getFullYear()) * 12 +
          today.getMonth() -
          DOB.getMonth();
        totalMonths += today.getDay() < DOB.getDay() ? -1 : 0;
        let years = today.getFullYear() - DOB.getFullYear();
        if (DOB.getMonth() > today.getMonth()) years = years - 1;
        else if (DOB.getMonth() === today.getMonth())
          if (DOB.getDate() > today.getDate()) years = years - 1;
  
        let days;
        let months;
  
        if (DOB.getDate() > today.getDate()) {
          months = totalMonths % 12;
          if (months == 0) months = 11;
          let x = today.getMonth();
          switch (x) {
            case 1:
            case 3:
            case 5:
            case 7:
            case 8:
            case 10:
            case 12: {
              let a = DOB.getDate() - today.getDate();
              days = 31 - a;
              break;
            }
            default: {
              let a = DOB.getDate() - today.getDate();
              days = 30 - a;
              break;
            }
          }
        } else {
          days = today.getDate() - DOB.getDate();
          if (DOB.getMonth() === today.getMonth()) months = totalMonths % 12;
          else months = totalMonths % 12;
        }
        let age = years + " ปี " + months + " เดือน " + days + " วัน";
        return age;
      },
  
      get_gov_Age() {
        let today = new Date(this.date_today_cal);
        let DOB = new Date(this.transference_personnels.ser_date);
        let totalMonths =
          (today.getFullYear() - DOB.getFullYear()) * 12 +
          today.getMonth() -
          DOB.getMonth();
        totalMonths += today.getDay() < DOB.getDay() ? -1 : 0;
        let years = today.getFullYear() - DOB.getFullYear();
        if (DOB.getMonth() > today.getMonth()) years = years - 1;
        else if (DOB.getMonth() === today.getMonth())
          if (DOB.getDate() > today.getDate()) years = years - 1;
  
        let days;
        let months;
  
        if (DOB.getDate() > today.getDate()) {
          months = totalMonths % 12;
          if (months == 0) months = 11;
          let x = today.getMonth();
          switch (x) {
            case 1:
            case 3:
            case 5:
            case 7:
            case 8:
            case 10:
            case 12: {
              let a = DOB.getDate() - today.getDate();
              days = 31 - a;
              break;
            }
            default: {
              let a = DOB.getDate() - today.getDate();
              days = 30 - a;
              break;
            }
          }
        } else {
          days = today.getDate() - DOB.getDate();
          if (DOB.getMonth() === today.getMonth()) months = totalMonths % 12;
          else months = totalMonths % 12;
        }
        let age = years + " ปี " + months + " เดือน " + days + " วัน";
        return age;
      }
    },
    props: {
      source: String
    }
  };
  </script>
  <style>
  @import url(
    https://fonts.googleapis.com/css?family=Prompt:400,
    300|Roboto:400,
    300&subset=latin,
    thai
  );
  
  body {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16px;
    font-weight: 250;
  }
  .text_al {
    text-align: left;
    vertical-align: top;
  }
  
  u {
    border-bottom: 1px dotted #000;
    text-decoration: none;
  }
  
  * {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    box-sizing: border-box;
    -moz-box-sizing: border-box;
  }
  
  .table,
  .th,
  .td {
    border-bottom: 0.5px solid black;
    border-top: 1px solid black;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-collapse: collapse;
  }
  
  p {
    text-indent: 50px;
  }

  p.indent {
    text-indent: 10px;
  }
  
  .text_j {
    border-collapse: collapse;
    text-align: justify;
    text-justify: inter-word;
    line-height: 1.3;
  }
  
  .text_j_1 {
    border-collapse: collapse;
    text-align: justify;
    text-justify: inter-word;
    line-height: 1;
  }
  
  tfoot tr:hover {
    background-color: initial;
  }
  
  tbody tr:hover {
    background-color: inherit;
  }
  
  td,
  th {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
  }
  
  tfoot td {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-weight: bold;
  }
  
  #verticaltext {
    writing-mode: tb-rl;
    filter: flipv fliph;
    -webkit-transform: rotate(-90deg);
    white-space: nowrap;
    display: block;
    overflow: hidden;
    padding: 0;
  }
  
  .page {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    width: 21cm;
    min-height: 29.7cm;
    border: 1px #d3d3d3 solid;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    margin-top: 0cm;
    margin-right: auto;
    margin-bottom: 0cm;
    margin-left: auto;
    padding-top: 1.5cm;
    padding-right: 2.5cm;
    padding-bottom: 1.75cm;
    padding-left: 2.5cm;
  }
  
  .head {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
    line-height: 1;
  }
  
  .regular12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 14pt;
  }
  
  .regular16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }
  
  .bold16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }
  
  .text_al {
    text-align: left;
    vertical-align: top;
  }
  
  .blod12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 14pt;
    font-weight: bold;
  }
  
  .blackRegula10 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 12pt;
  }
  
  .blackBold18 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 18pt;
    font-weight: bold;
  }
  
  .largerCheckbox {
    width: 30px;
    height: 30px;
    color: blue;
  }
  
  .td_line {
    border-bottom: 1pt dotted black;
  }
  
  /*.subpage {
        height: 247mm;
      }*/
  
  .BlackBold10 {
    font-weight: bold;
  }
  
  #content {
    display: table;
  }
  
  #pageFooter {
    display: table-footer-group;
  }
  
  #pageFooter:after {
    counter-increment: page;
    content: counter(page);
  }
  
  @media print {
    .page {
      border: initial;
      border-radius: initial;
      width: initial;
      min-height: initial;
      box-shadow: initial;
      background: initial;
      margin-top: 0cm;
      margin-right: auto;
      margin-bottom: 0cm;
      margin-left: auto;
      padding-top: 1.5cm;
      padding-right: 2.5cm;
      padding-bottom: 1.75cm;
      padding-left: 2.5cm;
    }
  
    .head {
      font-family: "TH SarabunIT๙", "TH SarabunPSK";
      font-size: 16pt;
      font-weight: bold;
      line-height: 1;
    }
  
    .regular12 {
      font-family: "TH SarabunIT๙", "TH SarabunPSK";
      font-size: 16pt;
    }
  
    .regular16 {
      font-family: "TH SarabunIT๙", "TH SarabunPSK";
      font-size: 16pt;
    }
  
    .bold16 {
      font-family: "TH SarabunIT๙", "TH SarabunPSK";
      font-size: 16pt;
      font-weight: bold;
    }
  
    .blod12 {
      font-family: "TH SarabunIT๙", "TH SarabunPSK";
      font-size: 16pt;
      font-weight: bold;
    }
  
    .blackRegula10 {
      font-family: "TH SarabunIT๙", "TH SarabunPSK";
      font-size: 14pt;
    }
  
    .blackBold18 {
      font-family: "TH SarabunIT๙", "TH SarabunPSK";
      font-size: 18pt;
      font-weight: bold;
    }
  
    /* .head {
          font-size: 18px;
          font-weight: bold;
        }
        .regular12 {
          font-size: 12px;
        }
        .blod12 {
          font-size: 12px;
          font-weight: bold;
        }
        .blackRegula8 {
          font-size: 8px;
        } */
    .noprint {
      display: none;
    }
  }
  </style>
  